import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(app)/addons/incoming-stock/add": [13,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/all-packets": [15,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/all-packets/arrived-today": [16,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/all-packets/arrived-today/products": [17,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/all": [14,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/archived": [18,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/editable": [19,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/not-arrived": [20,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/overview": [21,[2,4],[3]],
		"/(app)/addons/incoming-stock/list/supplier": [22,[2,4],[3]],
		"/(app)/addons/incoming-stock/packet/[packetId=uuidParam]": [23,[2,4],[3]],
		"/(app)/addons/incoming-stock/supplier/add": [27,[2,4],[3]],
		"/(app)/addons/incoming-stock/supplier/[supplierId=uuidParam]/general": [24,[2,4,5],[3]],
		"/(app)/addons/incoming-stock/supplier/[supplierId=uuidParam]/incomingStockPackets": [25,[2,4,5],[3]],
		"/(app)/addons/incoming-stock/supplier/[supplierId=uuidParam]/incomingStocks": [26,[2,4,5],[3]],
		"/(app)/addons/incoming-stock/[incomingStockId=uuidParam]": [11,[2,4],[3]],
		"/(app)/addons/incoming-stock/[incomingStockId=uuidParam]/edit": [12,[2,4],[3]],
		"/(app)/addons/internal-invoicing/pricing": [53,[2],[3]],
		"/(app)/addons/internal": [28,[2],[3]],
		"/(app)/addons/internal/admin": [29,[2],[3]],
		"/(app)/addons/internal/admin/blueprint-template": [30,[2],[3]],
		"/(app)/addons/internal/admin/blueprint-template/create": [31,[2],[3]],
		"/(app)/addons/internal/admin/customer-not-verified": [41,[2],[3]],
		"/(app)/addons/internal/admin/customer": [32,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/detail": [33,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/invoice": [34,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/invoice/[invoiceId=uuidParam]": [35,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/storage": [36,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/tools": [37,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/unrealized": [38,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/unrealized/add-position": [39,[2],[3]],
		"/(app)/addons/internal/admin/customer/[projectId=uuidParam]/unrealized/create": [40,[2],[3]],
		"/(app)/addons/internal/admin/incoming-invoice": [42,[2],[3]],
		"/(app)/addons/internal/admin/incoming-invoice/add": [46,[2],[3]],
		"/(app)/addons/internal/admin/incoming-invoice/[invoiceId]": [43,[2],[3]],
		"/(app)/addons/internal/admin/incoming-invoice/[invoiceId]/process": [44,[2],[3]],
		"/(app)/addons/internal/admin/incoming-invoice/[invoiceId]/process/positions": [45,[2],[3]],
		"/(app)/addons/internal/admin/supplier": [47,[2],[3]],
		"/(app)/addons/internal/admin/supplier/add": [48,[2],[3]],
		"/(app)/addons/internal/invoices": [50,[2],[3]],
		"/(app)/addons/internal/invoice/[invoiceId=uuidParam]": [49,[2],[3]],
		"/(app)/addons/internal/payment-methods": [51,[2],[3]],
		"/(app)/addons/internal/payment-methods/add": [52,[2],[3]],
		"/(app)/addons/issue": [54,[2],[3]],
		"/(app)/addons/issue/admin": [56,[2],[3]],
		"/(app)/addons/issue/new": [57,[2],[3]],
		"/(app)/addons/issue/[issueId=uuidParam]/[chatGroup=issueGroupParam]": [55,[2],[3]],
		"/(app)/addons/onboarding": [58,[2],[3]],
		"/(full-width)/addons/shop-shopify/installFailed/[shopName]": [98,[9]],
		"/(full-width)/addons/shop-shopify/register/[tmpId]": [99,[9]],
		"/(full-width)/addons/shop-shopify/wizard/products/[shopName]": [101,[9]],
		"/(full-width)/addons/shop-shopify/wizard/[shopName]": [100,[9]],
		"/(full-width)/addons/shop-shopware/register": [102,[9]],
		"/(app)/admin/tools": [59,[2],[3]],
		"/(app)/admin/tools/ev": [60,[2],[3]],
		"/(app)/admin/tools/shopify": [61,[2],[3]],
		"/(app)/admin/tools/uuid": [62,[2],[3]],
		"/(authentication)/auth/forgot-password": [92,[7]],
		"/(authentication)/auth/login": [93,[7]],
		"/(authentication)/auth/register": [94,[7]],
		"/(authentication)/auth/verify/email/[token=uuidParam]": [95,[7]],
		"/(authentication)/auth/verify/password-reset/[token=uuidParam]": [96,[7]],
		"/(app)/blueprint": [63,[2],[3]],
		"/(app)/blueprint/workspace/[workspaceId=uuidParam]": [65,[2],[3]],
		"/(app)/blueprint/[blueprintId=uuidParam]": [64,[2],[3]],
		"/(full-card)/contact": [97,[8]],
		"/(app)/customer": [66,[2],[3]],
		"/(app)/customer/[customerId=uuidParam]": [67,[2],[3]],
		"/(app)/customer/[customerId=uuidParam]/order": [68,[2],[3]],
		"/(full-width)/landing": [103,[9]],
		"/(app)/order": [69,[2],[3]],
		"/(app)/order/error": [71,[2],[3]],
		"/(app)/order/pending": [72,[2],[3]],
		"/(app)/order/[orderId=uuidParam]": [70,[2],[3]],
		"/(app)/product": [73,[2],[3]],
		"/(app)/product/group": [78,[2],[3]],
		"/(app)/product/group/add": [80,[2],[3]],
		"/(app)/product/group/[groupId=uuidParam]": [79,[2],[3]],
		"/(app)/product/internal": [81,[2],[3]],
		"/(app)/product/internal/add": [83,[2],[3]],
		"/(app)/product/internal/[productId=uuidParam]": [82,[2],[3]],
		"/(app)/product/manufacturer": [84,[2],[3]],
		"/(app)/product/manufacturer/[manufacturerId=uuidParam]": [85,[2],[3]],
		"/(app)/product/vendor": [86,[2],[3]],
		"/(app)/product/[productVariantId=uuidParam]/incoming-stock": [74,[2,6],[3]],
		"/(app)/product/[productVariantId=uuidParam]/overview": [75,[2,6],[3]],
		"/(app)/product/[productVariantId=uuidParam]/packet": [76,[2,6],[3]],
		"/(app)/product/[productVariantId=uuidParam]/stock": [77,[2,6],[3]],
		"/(full-width)/project/first": [104,[9]],
		"/(app)/project/new": [87,[2],[3]],
		"/(full-width)/project/shop/add/[addonName]": [105,[9]],
		"/(full-width)/project/shop/first": [106,[9]],
		"/(full-width)/project/shop/temporary": [107,[9]],
		"/(app)/settings": [88,[2],[3]],
		"/(app)/settings/[...categoryKey]": [89,[2],[3]],
		"/(app)/shop": [90,[2],[3]],
		"/(app)/shop/[shopId=uuidParam]": [91,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';