// reflect-metadata import has to be at the top of this file!
import 'reflect-metadata';

import { building, dev } from '$app/environment';
import { RELEASE_VERSION, SENTRY_DSN } from '$lib/util/env';
import { hasOwnProperty } from '@paralo-official/common';
import * as SentrySvelte from '@sentry/svelte';
import '@sentry/tracing';
import type { HandleClientError } from '@sveltejs/kit';

const enableSentry = !building && !dev;

if (enableSentry) {
    SentrySvelte.init({
        dsn: SENTRY_DSN,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^\//, ':7000', '192.168.', 'droplion.com'],
        integrations: [new SentrySvelte.Replay()],
        release: 'frontend@' + RELEASE_VERSION
    });

    SentrySvelte.setTag('svelteKit', 'browser');
}

// This will catch errors in load functions from +page.ts files
export const handleError = (({ error, event }) => {
    if (enableSentry) {
        SentrySvelte.captureException(error, { contexts: { svelteKit: { event } } });
    }

    return {
        message:
            error && typeof error === 'object' && hasOwnProperty(error, 'message') && typeof error.message === 'string'
                ? error.message
                : ''
    };
}) satisfies HandleClientError;
